// header.controller.js
"use strict";

class headerController {
  constructor(headerService, $window, addressListService, infoPerfilService, ngDialog, $q) {
    this.service = headerService;
    this.$window = $window;
    this.addressListService = addressListService;
    this.infoPerfilService = infoPerfilService;
    this.ngDialog = ngDialog;
    this.$q = $q;

    this.dialogoLoading;
    this.usuMenu = false;
    this.direcciones = false;
    this.datosPersonales = {
      "email": "",
      "fullName": "",
      "pictureUri": ""
    };
  }

  $onInit() {
    this.showLoading(true);
    const profileProm = this.infoPerfilService.getInfo();
    const addressProm = this.addressListService.getSelectedAddress();
    this.$q.all([profileProm, addressProm])
      .then((proms)=>{
        this.datosPersonales = proms[0];
        this.direccionSeleccionada = proms[1];
      })
      .catch((proms)=>{
        this.datosPersonales = proms[0];
        this.direccionSeleccionada = proms[1];
      })
      .finally(()=>this.showLoading(false));

  }

  showLoading(show) {
    if (show && !this.dialogoLoading) {
      this.dialogoLoading = this.ngDialog.open({
        template: '<logo-cargando data-efecto="ngDialogData.efecto" data-texto="ngDialogData.texto"></logo-cargando>',
        className: 'ngdialog-theme-default',
        plain: true,
        showClose: false,
        closeByDocument: false,
        data: {
          efecto: 'WGirando',
          texto: '<p class="mt-3">Accediendo ...</p>'
        }
      });
    } else if (!show && this.dialogoLoading) {
      this.dialogoLoading.close();
    }
  }

  getUserImage() {
    return this.datosPersonales.pictureUri || 'assets/userdefault.jpg';
  }

  getTiposContrato(direccion) {
    let tiposContrato = '';
    if (direccion.electricity.supplyId) {
      tiposContrato += 'Luz';
    }
    if (direccion.gas.supplyId) {
      tiposContrato += (tiposContrato !== '' ? ', ' : '') + 'Gas';
    }
    return tiposContrato;
  }

  logout() {
    this.$window.localStorage.removeItem('sweno-customer-token-validation');
    this.$window.location.href = '/login';
  }

  onDesplegarDireccionesClicked() {
    this.usuMenu = false;
  }

  onDireccionSeleccionada() {
    this.addressListService
      .getSelectedAddress()
      .then(selectedAddress => this.direccionSeleccionada = selectedAddress);
  }
}

headerController.$inject = ["headerService", "$window", "addressListService", "infoPerfilService", "ngDialog", "$q"];

export { headerController as HEADER_CONTROLLER };
