// app.js
"use strict";

import angular from "angular";
import "angular-i18n/angular-locale_es-es";
import "@uirouter/angularjs";
import "oclazyload";
import "bootstrap/dist/css/bootstrap.min.css";
import "./core/core.less";
// import { checkLoggedIn } from "./core/checkLoggedIn.run";
import { HEADER_MODULE } from "./components/header/header.module";
import { FOOTER_MODULE } from "./components/footer/footer.module";

import { appRouting, transitionHooks } from "./app.routing";
import { scroll } from "./app.scrollToTop.run";
import { authInterceptor } from "./core/auth.interceptor";
import { configHttp } from "./core/config.http";
import { ADDRESS_LIST_SERVICE } from "./core/address.list.service";


const SWENO_ENERGIA =  angular
    .module("swenoCustomer", [
        "ui.router",
        HEADER_MODULE,
        "oc.lazyLoad",
        FOOTER_MODULE
    ])
    .config(appRouting)
    .config(configHttp)
    .factory("authInterceptor", authInterceptor)
    .service("addressListService", ADDRESS_LIST_SERVICE)
    .run(scroll)
    .run(transitionHooks)
    // .run(checkLoggedIn)
    ;

export { SWENO_ENERGIA };
