// direcciones.controller.js
/* globals angular */
"use strict";

class direccionesController {
  constructor($state, addressListService) {
    this.$state = $state;
    this.addressListService = addressListService;

    this.desplegarDirecciones = false;
    this.datosDirecciones = [];
  }

  $onInit() {
    this.addressListService
      .getAddresses()
      .then(addresses => {
        this.datosDirecciones = addresses;
      });

    this.addressListService
      .getSelectedAddress()
      .then(selectedAddress => this.direccionSeleccionada = selectedAddress);
  }

  getTiposContrato(direccion) {
    let tiposContrato = '';
    if (direccion.electricity.supplyId) {
      tiposContrato += 'Luz';
    }
    if (direccion.gas.supplyId) {
      tiposContrato += (tiposContrato !== '' ? ', ' : '') + 'Gas';
    }
    return tiposContrato;
  }

  setDireccionSeleccionada(direccionSeleccionada) {
    if (!this.direccionSeleccionada || !this.direccionSeleccionada.id || this.direccionSeleccionada.id !== direccionSeleccionada.id) {
      this.direccionSeleccionada = angular.copy(direccionSeleccionada);
      this.addressListService.setSelectedAddress(this.direccionSeleccionada);
    }
    this.$state.go(this.$state.current.name, {idDireccion: direccionSeleccionada.id}, {reload: true});
    this.onDireccionSeleccionada();
  }

  desplegarDireccionesClicked() {
    this.desplegarDirecciones=!this.desplegarDirecciones;
    this.onDesplegarDireccionesClicked();
  }
}

direccionesController.$inject = ["$state", "addressListService","$scope"];

export { direccionesController as DIRECCIONES_CONTROLLER };
