// info-perfil.service.js
"use strict";

class infoPerfilService {
  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
    this.url = {
      info: '/api/customer/profile'
    };
    this.defer = undefined;
  }

  getInfo() {
    if (this.defer) {
      return this.defer.promise;
    }
    this.defer = this.$q.defer();
    this
      .$http
      .get(this.url.info)
      .then(data => this.defer.resolve(data.data))
      .catch(()=>this.defer.reject());
    return this.defer.promise;
  }
}

infoPerfilService.$inject = ["$http", "$q"];

export { infoPerfilService as INFO_PERFIL_SERVICE };
