// address.list.service.js
"use strict";

class addressListService {
  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
    this.url = {
      services: '/api/customer/services'
    };
    this.defer = {
      gettingAddresses: undefined,
      gettingSelectedAddress: undefined,
      settingSelectedAddressId: undefined
    };
  }

  getAddresses() {
    if (this.defer.gettingAddresses) {
      return this.defer.gettingAddresses.promise;
    }

    this.defer.gettingAddresses = this.$q.defer();
    if (this.addresses) {
      this.defer.gettingAddresses.resolve(this.addresses);
    } else {
        this.$http
            .get(this.url.services)
            .then(data => {
                this.addresses = this.getFormattedAddresses(data.data);
                this.defer.gettingAddresses.resolve(this.addresses);
            })
            .catch(()=> {
              this.defer.gettingAddresses.reject()
            });
    }

    return this.defer.gettingAddresses.promise;
  }

  getFormattedAddresses(addresses) {
    let _addresses = [];
    addresses.forEach(address => {

     /* let contractsActive = [];
      if (address?.contracts?.length > 0) {
        contractsActive = address.contracts.filter(c => c.active);
      }*/
      let contractsActive = address?.contracts ?? [];

      _addresses.push({
        id: address.id,
        address: address.address.road,
        locality: address.address.locality,
        postalCode: address.address.postalcode,
        province: address.address.province,
        electricity:
        {
            supplyId: contractsActive.length > 0 ? contractsActive[0].id : undefined,
            monthlyInterval: true
        },
        gas: {},
        cups: address.cups,
        contracts: contractsActive
      });
    })
    return _addresses;
  }

  setSelectedAddress(address) {
    this.selectedAddress = address;
    this.defer.gettingSelectedAddress.resolve(this.selectedAddress);
    this.defer.gettingSelectedAddress = this.$q.defer();
    this.defer.gettingSelectedAddress.resolve(this.selectedAddress);
  }

  initializeSelectedAddress(addressId) {
    this.defer.settingSelectedAddressId = this.$q.defer();
    this.getAddresses().then(addresses => {
      this.setSelectedAddress(addresses.find(addr => addr.id == addressId))
      this.defer.settingSelectedAddressId.resolve();
      this.defer.settingSelectedAddressId = undefined;
    })
    .catch(()=>{
      this.defer.settingSelectedAddressId.reject();
      this.defer.settingSelectedAddressId = undefined;
    });
    return this.defer.settingSelectedAddressId.promise;
  }

  getSelectedAddress() {
    if (this.defer.gettingSelectedAddress) {
      return this.defer.gettingSelectedAddress.promise;
    }

    this.defer.gettingSelectedAddress = this.$q.defer();
    if (this.selectedAddress) {
      this.defer.gettingSelectedAddress.resolve(this.selectedAddress);
    } else if (this.defer.settingSelectedAddressId) {
      this.defer.settingSelectedAddressId.promise.then(()=>this.defer.gettingSelectedAddress.resolve(this.selectedAddress));
    } else {
        this.getAddresses()
            .then(addressess=>{
                if (this.defer.settingSelectedAddressId) {
                  this.defer.settingSelectedAddressId.promise.then(()=>this.defer.gettingSelectedAddress.resolve(this.selectedAddress));
                  return;
                }
                this.setSelectedAddress(addressess[0]);
                this.defer.gettingSelectedAddress.resolve(this.selectedAddress);
            })
            .catch(()=>this.defer.gettingSelectedAddress.reject());
    }

    return this.defer.gettingSelectedAddress.promise;

  }

  getBCProducts(){
      if (this.defer.gettingBC) {
        return this.defer.gettingBC.promise;
      }
      this.defer.gettingBC = this.$q.defer();
      if (this.bc_list) {
        this.defer.gettingBC.resolve(this.bc_list);
      } else {
          this.$http
              .get(this.url.services)
              .then(data => {
                          var bc_product_list = [];
                          data.data.forEach(function(dato) {
                              dato["contracts"].forEach(function(contrato) {
                                  if(contrato["active"]){
                                    bc_product_list.push(contrato["bill_classification"]);
                                  }
                              });
                          });
                  this.bc_list = bc_product_list;
                  this.defer.gettingBC.resolve(this.bc_list);
              })
              .catch(()=> {
                this.defer.gettingBC.reject()
              });
      }
      return this.defer.gettingBC.promise;
  }
}

addressListService.$inject = ["$http", "$q"];

export { addressListService as ADDRESS_LIST_SERVICE };
