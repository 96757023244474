// app.states.js

"use strict";

const customer = {
    name: "customer",
    url: "/customer?:idDireccion",
    component: "inicio",
    lazyLoad: ($transition$) => {
      const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
      return import(/* webpackChunkName: "inicio.module" */ "./components/inicio/inicio.module")
        .then(mod => $ocLazyLoad.inject(mod.INICIO_MODULE))
        .catch(err => {
          throw new Error("Ooops, something went wrong, " + err);
        });
    },
    onEnter: ["$document", ($document) => {
      $document[0].title = "Energía El Corte Inglés";
      $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Únete a Energía El Corte Inglés y tendrás el precio y la atención de calidad que te mereces con todas las garantías del Grupo El Corte Inglés');
      $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles, Luz El Corte Ingles, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
      $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://energiaelcorteingles.es/customer');
      $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://energiaelcorteingles.es","legalName": "","description": "","url": "https://energiaelcorteingles.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/energiaelcorteingles","https://twitter.com/energiaelcorteingles","https://www.instagram.com/energiaelcorteingles","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/energiaelcorteingles"]},{"@type": "WebSite","name": "Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://energiaelcorteingles.es/customer"}]}';
    }]
};

const misProductos = {
  name: "misProductos",
  url: "/customer/misProductos?:idDireccion",
  component: "misProductos",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "mis-productos.module" */ "./components/mis-productos/mis-productos.module")
      .then(mod => $ocLazyLoad.inject(mod.MIS_PRODUCTOS_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Energía El Corte Inglés";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Únete a Energía El Corte Inglés y tendrás el precio y la atención de calidad que te mereces con todas las garantías del Grupo El Corte Inglés');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles, Luz El Corte Ingles, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://energiaelcorteingles.es/customer');
    $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://energiaelcorteingles.es","legalName": "","description": "","url": "https://energiaelcorteingles.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/energiaelcorteingles","https://twitter.com/energiaelcorteingles","https://www.instagram.com/energiaelcorteingles","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/energiaelcorteingles"]},{"@type": "WebSite","name": "Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://energiaelcorteingles.es/customer"}]}';
  }]
};

const misServicios = {
  name: "misServicios",
  url: "/customer/misServicios?:idDireccion",
  component: "misServicios",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "mis-productos.module" */ "./components/mis-servicios/mis-servicios.module")
      .then(mod => $ocLazyLoad.inject(mod.MIS_SERVICIOS_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Energía El Corte Inglés";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Únete a Energía El Corte Inglés y tendrás el precio y la atención de calidad que te mereces con todas las garantías del Grupo El Corte Inglés');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles, Luz El Corte Ingles, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://energiaelcorteingles.es/customer');
    $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://energiaelcorteingles.es","legalName": "","description": "","url": "https://energiaelcorteingles.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/energiaelcorteingles","https://twitter.com/energiaelcorteingles","https://www.instagram.com/energiaelcorteingles","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/energiaelcorteingles"]},{"@type": "WebSite","name": "Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://energiaelcorteingles.es/customer"}]}';
  }]
};

const incidencias = {
  name: "incidencias",
  url: "/customer/consultas?:idDireccion",
  component: "incidencias",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "incidencias.module" */ "./components/incidencias/incidencias.module")
      .then(mod => $ocLazyLoad.inject(mod.INCIDENCIAS_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Energía El Corte Inglés";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Únete a Energía El Corte Inglés y tendrás el precio y la atención de calidad que te mereces con todas las garantías del Grupo El Corte Inglés');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles, Luz El Corte Ingles, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://energiaelcorteingles.es/customer');
    $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://energiaelcorteingles.es","legalName": "","description": "","url": "https://energiaelcorteingles.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/energiaelcorteingles","https://twitter.com/energiaelcorteingles","https://www.instagram.com/energiaelcorteingles","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/energiaelcorteingles"]},{"@type": "WebSite","name": "Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://energiaelcorteingles.es/customer"}]}';
  }]
};

const misFacturas = {
    name: "misFacturas",
    url: "/customer/misFacturas?:idDireccion",
    component: "misFacturas",
    lazyLoad: ($transition$) => {
      const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
      return import(/* webpackChunkName: "mis-facturas.module" */ "./components/mis-facturas/mis-facturas.module")
        .then(mod => $ocLazyLoad.inject(mod.MIS_FACTURAS_MODULE))
        .catch(err => {
          throw new Error("Ooops, something went wrong, " + err);
        });
    },
    onEnter: ["$document", ($document) => {
      $document[0].title = "Energía El Corte Inglés";
      $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Únete a Energía El Corte Inglés y tendrás el precio y la atención de calidad que te mereces con todas las garantías del Grupo El Corte Inglés');
      $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles, Luz El Corte Ingles, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
      $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://energiaelcorteingles.es/customer');
      $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://energiaelcorteingles.es","legalName": "","description": "","url": "https://energiaelcorteingles.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/energiaelcorteingles","https://twitter.com/energiaelcorteingles","https://www.instagram.com/energiaelcorteingles","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/energiaelcorteingles"]},{"@type": "WebSite","name": "Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://energiaelcorteingles.es/customer"}]}';
    }]
};
const miPerfil = {
    name: "miPerfil",
    url: "/customer/miPerfil",
    component: "miPerfil",
    lazyLoad: ($transition$) => {
      const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
      return import(/* webpackChunkName: "mi-perfil.module" */ "./components/mi-perfil/mi-perfil.module")
        .then(mod => $ocLazyLoad.inject(mod.MI_PERFIL_MODULE))
        .catch(err => {
          throw new Error("Ooops, something went wrong, " + err);
        });
    },
    onEnter: ["$document", ($document) => {
      $document[0].title = "Energía El Corte Inglés";
      $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Únete a Energía El Corte Inglés y tendrás el precio y la atención de calidad que te mereces con todas las garantías del Grupo El Corte Inglés');
      $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles, Luz El Corte Ingles, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
      $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://energiaelcorteingles.es/customer');
      $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://energiaelcorteingles.es","legalName": "","description": "","url": "https://energiaelcorteingles.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/energiaelcorteingles","https://twitter.com/energiaelcorteingles","https://www.instagram.com/energiaelcorteingles","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/energiaelcorteingles"]},{"@type": "WebSite","name": "Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://energiaelcorteingles.es/customer"}]}';
    }]
};
const misContratos = {
    name: "misContratos",
    url: "/customer/misContratos?:idDireccion",
    component: "misContratos",
    lazyLoad: ($transition$) => {
      const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
      return import(/* webpackChunkName: "mis-contratos.module" */ "./components/mis-contratos/mis-contratos.module")
        .then(mod => $ocLazyLoad.inject(mod.MIS_CONTRATOS_MODULE))
        .catch(err => {
          throw new Error("Ooops, something went wrong, " + err);
        });
    },
    onEnter: ["$document", ($document) => {
      $document[0].title = "Energía El Corte Inglés";
      $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Únete a Energía El Corte Inglés y tendrás el precio y la atención de calidad que te mereces con todas las garantías del Grupo El Corte Inglés');
      $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles, Luz El Corte Ingles, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
      $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://energiaelcorteingles.es/customer');
      $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://energiaelcorteingles.es","legalName": "","description": "","url": "https://energiaelcorteingles.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/energiaelcorteingles","https://twitter.com/energiaelcorteingles","https://www.instagram.com/energiaelcorteingles","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/energiaelcorteingles"]},{"@type": "WebSite","name": "Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://energiaelcorteingles.es/customer"}]}';
    }]
};
const recomiendaAmigo = {
  name: "recomiendaAmigo",
  url: "/customer/recomiendaAmigo",
  component: "recomiendaAmigo",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "recomienda-amigo.module" */ "./components/recomienda-amigo/recomienda-amigo.module")
      .then(mod => $ocLazyLoad.inject(mod.RECOMIENDA_AMIGO_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Sweno Energia";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Unete a Sweno Energia y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Sweno energia, Luz Sweno, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://swenoenergia.es/customer');
    $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://swenoenergia.es","legalName": "","description": "","url": "https://swenoenergia.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/swenoenergia","https://twitter.com/swenoenergia","https://www.instagram.com/swenoenergia","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/swenoenergia"]},{"@type": "WebSite","name": "Unete a Sweno Energia y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://swenoenergia.es/customer"}]}';
  }]
};
const ayuda = {
    name: "ayuda",
    url: "/customer/ayuda",
    component: "ayuda",
    lazyLoad: ($transition$) => {
      const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
      return import(/* webpackChunkName: "ayuda.module" */ "./components/ayuda/ayuda.module")
        .then(mod => $ocLazyLoad.inject(mod.AYUDA_MODULE))
        .catch(err => {
          throw new Error("Ooops, something went wrong, " + err);
        });
    },
    onEnter: ["$document", ($document) => {
      $document[0].title = "Energía El Corte Inglés";
      $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Únete a Energía El Corte Inglés y tendrás el precio y la atención de calidad que te mereces con todas las garantías del Grupo El Corte Inglés');
      $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles, Luz El Corte Ingles, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
      $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://energiaelcorteingles.es/customer');
      $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://energiaelcorteingles.es","legalName": "","description": "","url": "https://energiaelcorteingles.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/energiaelcorteingles","https://twitter.com/energiaelcorteingles","https://www.instagram.com/energiaelcorteingles","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/energiaelcorteingles"]},{"@type": "WebSite","name": "Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://energiaelcorteingles.es/customer"}]}';
    }]
};
export { customer, misProductos, misServicios, misFacturas, miPerfil, misContratos, recomiendaAmigo, ayuda, incidencias };
