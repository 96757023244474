// auth.interceptor.js
/* globals apiKey, withTrace */
"use strict"

function authInterceptor($window) {
  const urlParams = new URLSearchParams(window.location.search);
  const contact = urlParams.get("contactform") || "";
  const facturas = urlParams.get("redir") || "";
  const params = [];
  if (contact) {
    params.push("contactform=" + contact);
  }
  if (facturas) {
    params.push("redir=" + facturas);
  }

  const paramString = params.length > 0 ? "?" + params.join("&") : "";


  return {
    request: function(config) {
      config.headers = config.headers || {};
      config.headers['Ocp-Apim-Subscription-Key'] = apiKey;
      config.headers['Ocp-Apim-Trace'] = withTrace;
            let token = $window.localStorage.getItem('sweno-customer-token-validation');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }else{
        $window.location.href = '/login'+paramString;
      }
      return config;
    },
    responseError: function(response) {
      switch (response.status) {
        case 401:
          $window.location.href = '/login'+paramString;
          return;
        default:
          return response;
      }
    }
  };
}

authInterceptor.$inject = ["$window"];

export { authInterceptor };
