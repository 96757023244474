// app.routing.js

"use strict";
import {customer} from "./app.states";
import {misProductos} from "./app.states";
import {misServicios} from "./app.states";
import {misFacturas} from "./app.states";
import {miPerfil} from "./app.states";
import {recomiendaAmigo} from "./app.states";
import {misContratos} from "./app.states";
import {ayuda} from "./app.states";
import {incidencias} from "./app.states";

appRouting.$inject = ["$stateProvider", "$urlRouterProvider", "$locationProvider", "$urlMatcherFactoryProvider"];

function appRouting($stateProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $urlRouterProvider.otherwise("/customer");
  $locationProvider.html5Mode(true);
  $stateProvider.state(customer);
  $stateProvider.state(misProductos);
  $stateProvider.state(misServicios);
  $stateProvider.state(misFacturas);
  $stateProvider.state(miPerfil);
  $stateProvider.state(recomiendaAmigo);
  $stateProvider.state(misContratos);
  $stateProvider.state(ayuda);
  $stateProvider.state(incidencias);
}

transitionHooks.$inject = ["$transitions", "addressListService"];

function transitionHooks($transitions, addressListService) {
  const criteriaObj = {
    to: (state) => ["customer", "misProductos", "misServicios", "misFacturas", "misContratos","incidencias"].includes(state.name)
  };
  $transitions.onBefore(criteriaObj, function($transition) {
    const paramsCopy = Object.assign({}, $transition.params());
    const stateService = $transition.router.stateService;

    if (paramsCopy.idDireccion) {
      addressListService.initializeSelectedAddress(paramsCopy.idDireccion);
      return true;
    }

    return addressListService.getSelectedAddress().then(selectedAddress=>{
      paramsCopy.idDireccion = selectedAddress.id;
      return stateService.target($transition.to(), paramsCopy);
    });
  });
}

export { appRouting, transitionHooks };
