// footer.controller.js
/* globals redirectHome */
"use strict";

class footerController {
    constructor(addressListService) {
        this.defaultTarget = {
            centroAyuda: undefined,
            contacto: undefined,
            privacidad: undefined,
            condiciones: undefined,
            cookies: undefined,
            bases: undefined
        };
        this.evaluateTarget();
        this.addressListService = addressListService;
        this.bbppLinks = {
            'pg': redirectHome + 'wp-content/uploads/2023/10/PG_Bienvenida.pdf',
            'empleado':redirectHome + 'wp-content/uploads/2023/09/Empleados_Bienvenida.pdf',
            'amigo': redirectHome + 'wp-content/uploads/2023/09/FYF_Bienvenida.pdf'
        }
    }

    $onInit() {
        this.homeLinkHelp = redirectHome + 'centroAyuda';
        this.homeLinkContact = redirectHome + 'contacto';
        this.homeLinkLegalesBases = redirectHome + 'aviso-legal/';
        this.homeLinkLegalesPrivacidad = redirectHome + 'politica-de-privacidad/';
        this.homeLinkLegalesCondiciones = redirectHome + 'legales/condiciones';
        this.homeLinkLegalesCookies = redirectHome + 'politica-de-cookies/';
        this.homeLinkBasesPromocionales = redirectHome + 'wp-content/uploads/2023/10/PG_Bienvenida.pdf';
        this.service
        var that = this;
        this.addressListService
        .getSelectedAddress()
        .then(data => {
            var productName = data['contracts'][0]['products'][0]['name'];
            var bbppKey = 'pg';
            if(productName.toLowerCase().includes('empleado'))
                bbppKey = 'empleado';
            else if(productName.toLowerCase().includes('amigo'))
                bbppKey = 'amigo';
            that.homeLinkBasesPromocionales = that.bbppLinks[bbppKey];
        });
        this.homeLinkCondicionesContratacion = redirectHome + 'wp-content/uploads/2023/06/condiciones_generales_contrato_suministro_energiaelcorteingles.pdf';
        this.evaluateTarget();
        //variable seteada temporalmente para mostrar el foot
        //cuando diga comercial
        this.muestraFoot=false;
    }

    $onChange() {
        this.evaluateTarget();
    }

    evaluateTarget() {
        this.targetLocation = Object.assign({}, this.defaultTarget);
        if (this.target) {
            if (typeof this.target === 'string') {
                Object.entries(this.targetLocation).forEach(([key]) => {
                    this.targetLocation[key] = this.target;
                });
            } else if (typeof this.target === 'object') {
                this.targetLocation = Object.assign(this.targetLocation, this.target);
            }
        }
    }
}

footerController.$inject = ["addressListService","$scope"];

export { footerController as FOOTER_CONTROLLER };
