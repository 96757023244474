//header.component.js
"use strict";

import template from './header.view.html';
import { HEADER_CONTROLLER } from './header.controller';
import './header.less';
import 'ng-dialog/css/ngDialog.css';
import 'ng-dialog/css/ngDialog-theme-plain.css';
import 'ng-dialog/css/ngDialog-theme-default.css';

let HEADER_COMPONENT = {
    restrict: 'E',
    bindings: {},
    template,
    controller: HEADER_CONTROLLER,
    controllerAs: "header"
};

export { HEADER_COMPONENT };
