// header.module.js
"use strict";

import angular from "angular";
import { FOOTER_COMPONENT } from "./footer.component";
import { DIRECCIONES_MODULE } from "../comunes/direcciones/direcciones.module";


const FOOTER_MODULE = angular
    .module("footer", ["ngDialog", DIRECCIONES_MODULE])
    .component("footer", FOOTER_COMPONENT)
    .name
;

export { FOOTER_MODULE };
