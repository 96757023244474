// info-perfil.module.js
"use strict";

import angular from "angular";
import { INFO_PERFIL_SERVICE } from "./info-perfil.service";

const INFO_PERFIL_MODULE = angular
    .module("infoPerfil", [])
    .service("infoPerfilService", INFO_PERFIL_SERVICE)
    .name
;

export { INFO_PERFIL_MODULE };
