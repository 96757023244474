// direcciones.module.js
"use strict";

import angular from "angular";
import { DIRECCIONES_COMPONENT } from "./direcciones.component";

const DIRECCIONES_MODULE = angular
    .module("direcciones", [])
    .component("direcciones", DIRECCIONES_COMPONENT)
    .name
;

export { DIRECCIONES_MODULE };
