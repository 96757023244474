// header.service.js
"use strict";

class headerService {
  constructor() {
  }
}

headerService.$inject = [];

export { headerService as HEADER_SERVICE };
