//direcciones.component.js
"use strict";

import template from './direcciones.view.html';
import { DIRECCIONES_CONTROLLER } from './direcciones.controller';
import './direcciones.less';

let DIRECCIONES_COMPONENT = {
    restrict: 'E',
    bindings: {
        desplegarDirecciones: '=',
        onDesplegarDireccionesClicked: '&',
        onDireccionSeleccionada: '&'
    },
    template,
    controller: DIRECCIONES_CONTROLLER,
    controllerAs: "direcciones"
};

export { DIRECCIONES_COMPONENT };
