// header.module.js
"use strict";

import angular from "angular";
import { HEADER_COMPONENT } from "./header.component";
import { HEADER_SERVICE } from "./header.service";
import { DIRECCIONES_MODULE } from "../comunes/direcciones/direcciones.module";
import { INFO_PERFIL_MODULE } from "../comunes/info-perfil/info-perfil.module";

import "@telecor-web/logo-cargando";
import "ng-dialog";

const HEADER_MODULE = angular
    .module("header", ["ngDialog", "logoCargando", DIRECCIONES_MODULE, INFO_PERFIL_MODULE])
    .service("headerService", HEADER_SERVICE)
    .component("header", HEADER_COMPONENT)
    .name
;

export { HEADER_MODULE };
