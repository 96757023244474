//footer.component.js
"use strict";

import template from './footer.view.html';
import { FOOTER_CONTROLLER } from './footer.controller';
import './footer.less';

let FOOTER_COMPONENT = {
    restrict: 'E',
    bindings: {
        target: '<',
        contactGoHelp: '<'
    },
    template,
    controller: FOOTER_CONTROLLER,
    controllerAs: "Footer"
};

export { FOOTER_COMPONENT };
